import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import './index.css'
import { initializeConfig } from './config';
// import { SnackbarProvider } from 'notistack';


initializeConfig().then(() => {

  const Root = React.lazy(() => import('./layout/ui/root'));

  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        {/* <SnackbarProvider> */}

        <Suspense fallback={
          <>{"Loading..."}</>
          // <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          //   <CircularProgress color="inherit" />
          // </Backdrop>
        }>

          <Root />
        </Suspense>
        {/* </SnackbarProvider> */}

      </StyledEngineProvider>
    </React.StrictMode>,
  )


})
